import React from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import RichText from 'atoms/RichText';

import scale from 'utils/scale';
import media from 'utils/media';

const isBrowser = typeof window !== 'undefined';

const cookies = new Cookies();

const Text = styled.p`
  font-size: ${props => props.theme.type.smallXS.fontsize}rem;
  line-height: ${props => props.theme.type.smallXS.lineheight}rem;

  ${media.sm`
    padding-top: ${scale(0.25)};
    font-size: ${props => props.theme.type.small.fontsize}rem;
    line-height: ${props => props.theme.type.small.lineheight}rem;
  `};

  && {
    padding-top: ${scale(1.5)};

    ${media.md`
      padding-top: 0;
    `};
  }
`;

export default ({ language }) => {
  const targetLanguage = language === 'de' ? 'en' : 'de';
  const targetHref = language === 'de' ? '/' : '/de';
  const linkText =
    language === 'de'
      ? 'Switch to English version'
      : 'Zur deutschen Version wechseln';

  const setLanguage = e => {
    if (!isBrowser) return;

    e.preventDefault();

    const days = 90;

    cookies.set('nf_lang', targetLanguage, {
      path: '/',
      maxAge: days * 24 * 3600,
    });

    window.location.href = targetHref;
  };

  return (
    <RichText>
      <Text>
        <a
          lang={targetLanguage}
          hrefLang={targetLanguage}
          href={targetHref}
          onClick={setLanguage}
        >
          {linkText}
        </a>
      </Text>
    </RichText>
  );
};
