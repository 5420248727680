import React from 'react';

import NavigationListItem from 'components/NavigationListItem';
import Scrollspy from 'modules/Scrollspy';

export default class NavigationItem extends React.PureComponent {
  render() {
    const {
      isLogo,
      isActive,
      spaceAfter,
      isExpanded,
      children,
      ...props
    } = this.props;

    return (
      <NavigationListItem
        isExpanded={isExpanded}
        isActive={isActive}
        isLogo={isLogo}
        spaceAfter={spaceAfter}
      >
        <Scrollspy.Link {...props}>{children}</Scrollspy.Link>
      </NavigationListItem>
    );
  }
}
