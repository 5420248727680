import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Heading from 'atoms/Heading';
import RichText from 'atoms/RichText';
import Navigation from 'components/Navigation';
import NavigationListItem from 'components/NavigationListItem';
import Toggler from 'modules/Toggler';

import media from 'utils/media';
import grid, { GridContainer, gridItem } from 'utils/grid';
import scale from 'utils/scale';
import { getI18nUrl } from 'utils/i18n';

const Main = styled(GridContainer)`
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled.div`
  ${gridItem(1)};
  padding-top: ${props => scale(props.theme.measures.mainOffsetTop.xs)};

  ${media.md`
    ${gridItem(2 / 4)}

    margin-left: ${grid(1 / 4)};
    padding-top: ${props => scale(props.theme.measures.mainOffsetTop.md)};
  `};
`;

export default class Page extends React.PureComponent {
  render() {
    const { data } = this.props;

    const { title, lang, defaultLang } = data.content.edges[0].node.fields;

    const settings = data.settings.edges[0].node.data;
    const content = data.content.edges[0].node.data;

    return (
      <>
        <Helmet>
          <html lang={lang} />

          <title>{`${title} – ${settings.page_title}`}</title>
          <meta name="description" content={settings.page_description} />

          {content.robots === 'noindex' && (
            <meta name="robots" content="noindex, follow" />
          )}

          {settings.opengraph_image.url && (
            <meta property="og:image" content={settings.opengraph_image.url} />
          )}
        </Helmet>

        <Toggler>
          {({ isOn, onToggle }) => (
            <Navigation activeIndex={0} onToggle={onToggle} isExpanded={isOn}>
              <NavigationListItem isExpanded={isOn} isActive isLogo spaceAfter>
                <p>{title}</p>
              </NavigationListItem>

              <NavigationListItem
                isExpanded={isOn}
                isActive={false}
                isLogo={false}
                spaceAfter={false}
              >
                <Link to={getI18nUrl(lang, defaultLang)}>
                  {lang === 'de' ? 'zurück' : 'back'}
                </Link>
              </NavigationListItem>
            </Navigation>
          )}
        </Toggler>

        <Main as="main">
          <Content>
            <Heading>{title}</Heading>

            <RichText htmlContent={content.text.html} />
          </Content>
        </Main>
      </>
    );
  }
}

export const query = graphql`
  query PageQuery($lang: String!, $slug: String!) {
    content: allPrismicSimplePage(
      filter: { lang: { eq: $lang }, uid: { eq: $slug } }
    ) {
      edges {
        node {
          fields {
            title
            lang
            defaultLang
          }
          data {
            robots
            text {
              html
            }
          }
        }
      }
    }

    settings: allPrismicSettings(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            page_title
            page_description
            opengraph_image {
              url
            }
          }
        }
      }
    }
  }
`;
