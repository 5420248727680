import React from 'react';
import Waypoint from 'react-waypoint';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { globalHistory } from '@reach/router/lib/history';

import theme from 'utils/theme';

import { ENTER, LEAVE } from './constants';
import Link from './Link';
import Section from './Section';

const isBrowser = typeof window !== 'undefined';

const breakpointMD = parseInt(theme.breakpoints[1].replace('em', ''), 10) * 16;
const topPositionComputed = theme.measures.mainOffsetTop.md * theme.scale * 16;

export default class Scrollspy extends React.PureComponent {
  static Link = ({
    target,
    activeSection,
    getPathRoot,
    handleClick,
    ...props
  }) => (
    <Link
      target={target}
      hashLink={`${getPathRoot()}#${target}`}
      handleClick={handleClick}
      {...props}
    />
  );

  static Section = ({ section, sectionRefs, handleTraverse, ...props }) => (
    <Section
      section={section}
      handleTraverse={handleTraverse}
      innerRef={sectionRefs[section]}
      {...props}
    />
  );

  render() {
    const { children, ...props } = this.props;

    return (
      <Router>
        <ScrollspyBase default render={children} {...props} />
        <ScrollspyBase
          path={this.props.routerPath}
          render={children}
          {...props}
        />
      </Router>
    );
  }
}

class ScrollspyBase extends React.PureComponent {
  static getSafeSection = (section, sections) =>
    typeof section === 'undefined' || !sections.includes(section)
      ? sections[0]
      : section;

  onLinkClick = section => {
    this.updateLocation(section);
    this.scrollToSection(section);
  };

  scrollToSection = section => {
    if (!isBrowser) return;

    const { scrollTo } = this.props.scrollerContext;
    const { sections } = this.props;
    const { activeSection } = this.state;
    const { sectionRefs } = this.state.sectionProps;

    let targetPos;

    // set target position depending on breakpoint and if the site will have to scroll up or down
    if (window.innerWidth < breakpointMD) {
      targetPos = sectionRefs[section].current.offsetTop;
    } else {
      targetPos =
        sections.indexOf(activeSection) < sections.indexOf(section)
          ? sectionRefs[section].current.offsetTop - topPositionComputed
          : sectionRefs[section].current.offsetTop;
    }

    scrollTo({ to: targetPos });
  };

  onWaypointTraverse = data => {
    const {
      section,
      direction,
      event,
      currentPosition,
      previousPosition,
    } = data;

    const { sections } = this.props;

    const { isAnimatingScroll } = this.props.scrollerContext;

    // the user didn't actually scroll the page or the scroll is coming from a scroll-animation
    if (!event || isAnimatingScroll) return;

    const sectionIndex = sections.indexOf(section);

    let nextSection;

    // the section hits the top of the viewport
    if (direction === LEAVE && currentPosition === Waypoint.above) {
      nextSection = sections[sectionIndex + 1];

      // the section detaches from the top of the viewport
    } else if (direction === ENTER && previousPosition === Waypoint.above) {
      nextSection = sections[sectionIndex];
    }

    // if we have a valid waypoint traverse, let's update the location
    if (typeof nextSection !== 'undefined') {
      this.updateLocation(nextSection);
    }
  };

  initHistoryListener = () => {
    // check if location change through change in browser history (e.g. back-button)
    globalHistory.listen(({ location, action }) => {
      if (action === 'POP') {
        const section = this.getIdFromPath(location.pathname);

        this.scrollToSection(section);
        navigate(location.pathname, { replace: true });
      }
    });
  };

  updateLocation = section => {
    navigate(this.getPathFromId(section));
  };

  getActiveSection = () => {
    const { section, sections } = this.props;

    return ScrollspyBase.getSafeSection(section, sections);
  };

  getPathRoot = () => this.props.routerPath.replace(':section', '');

  getPathFromId = id => {
    const { sections } = this.props;

    // check if we're on index
    return sections.indexOf(id) === 0
      ? this.getPathRoot()
      : this.props.routerPath.replace(':section', id);
  };

  getIdFromPath = path => {
    const unsafeSection = path.replace(this.getPathRoot(), '');

    return unsafeSection !== '' ? unsafeSection : this.props.sections[0];
  };

  getRefsForSections = () =>
    this.props.sections.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: React.createRef(),
      }),
      {}
    );

  performInitialScrollTo = () => {
    const { scrollTo } = this.props.scrollerContext;
    const { sectionRefs } = this.state.sectionProps;

    scrollTo({ to: sectionRefs[this.getActiveSection()].current.offsetTop });
  };

  /* eslint-disable react/no-unused-state */
  state = {
    activeSection: this.getActiveSection(),
    linkProps: {
      getPathRoot: this.getPathRoot,
      handleClick: this.onLinkClick,
    },
    sectionProps: {
      handleTraverse: this.onWaypointTraverse,
      sectionRefs: this.getRefsForSections(),
    },
  };
  /* eslint-enable react/no-unused-state */

  static getDerivedStateFromProps(nextProps, prevState) {
    const section = ScrollspyBase.getSafeSection(
      nextProps.section,
      nextProps.sections
    );

    return section === prevState.activeSection
      ? null
      : { activeSection: section };
  }

  componentDidMount() {
    this.performInitialScrollTo();
    this.initHistoryListener();
  }

  render() {
    return this.props.render(this.state);
  }
}
