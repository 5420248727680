import React from 'react';

export default ({
  target,
  hashLink,
  onClick,
  handleClick,
  children,
  ...props
}) => (
  <a
    href={hashLink}
    onClick={e => {
      e.preventDefault();
      handleClick(target);

      typeof onClick !== 'undefined' && onClick();
    }}
    {...props}
  >
    {children}
  </a>
);
