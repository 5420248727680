import React from 'react';

import Image from 'components/Image';

import Slider from './Slider';
import Slide from './Slide';

export default ({ slides, isNarrow }) => {
  const slidesSanitized = slides.filter(slide => !!slide.image.localFile);

  return (
    <>
      {slidesSanitized.length === 1 ? (
        <Image
          image={slidesSanitized[0].image.localFile.childImageSharp.fluid}
          alt={slidesSanitized[0].image.alt}
          caption={slidesSanitized[0].caption}
          isNarrow={isNarrow}
        />
      ) : (
        <Slider numSlides={slidesSanitized.length}>
          {({ activeSlide, handleNext, handlePrev }) =>
            slidesSanitized.map((item, i) => (
              <Slide
                key={item.image.localFile.childImageSharp.fluid.src}
                isActive={activeSlide === i}
                image={item.image.localFile.childImageSharp.fluid}
                alt={item.image.alt}
                caption={item.caption}
                index={i}
                numSlides={slides.length}
                isNarrow={isNarrow}
                onNext={handleNext}
                onPrev={handlePrev}
              />
            ))
          }
        </Slider>
      )}
    </>
  );
};
