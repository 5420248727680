import React from 'react';
import styled from 'styled-components';

import RichText from 'atoms/RichText';

import scale from 'utils/scale';
import media from 'utils/media';

const List = styled.ul`
  && {
    padding: 0;
    margin-top: ${scale(2)};
    font-size: ${props => props.theme.type.smallXS.fontsize}rem;
    line-height: ${props => props.theme.type.base.lineheight}rem;
    list-style: none;

    ${media.sm`
      font-size: ${props => props.theme.type.small.fontsize}rem;
      line-height: ${props => props.theme.type.base.lineheight}rem;
    `};
  }
`;

const ListItem = styled.li`
  padding: 0;

  &&::before {
    display: none;
  }
`;

export default ({ data }) => (
  <RichText>
    <List>
      {data.map(item => (
        <ListItem key={item.key}>
          <a href={item.target}>{item.title}</a>
        </ListItem>
      ))}
    </List>
  </RichText>
);
