import React from 'react';
import styled from 'styled-components';

import Heading from 'atoms/Heading';
import RichText from 'atoms/RichText';
import LanguageLink from 'components/LanguageLink';
import FooterNavigation from 'components/FooterNavigation';
import Gallery from 'modules/Gallery';
import ProjectList from 'modules/ProjectList';

import { GridItem } from 'utils/grid';
import scale from 'utils/scale';
import media from 'utils/media';

const Item = styled(GridItem)`
  padding-top: ${scale(1)};

  &&:first-child {
    padding-top: 0;
  }

  :nth-child(2n + 1) {
    padding-top: ${scale(3.5)};
  }

  ${media.md`
    padding-top: ${scale(1)};
  `};

  ${media.xl`
    &&:nth-child(2) {
      padding-top: 0;
    }

    :nth-child(2n + 2) {
      padding-top: ${scale(3.5)};
    }
  `};
`;

export default class ContentPage extends React.PureComponent {
  render() {
    const {
      uid,
      isActive,
      showLanguageLink,
      currentLanguage,
      footerNavigation,
    } = this.props;
    const { data, fields } = this.props.data;

    return (
      <>
        {data.body.map(block => {
          const key = `${uid}-${block.id}`;
          const isNarrowColumn =
            block.primary &&
            typeof block.primary.column_width !== 'undefined' &&
            block.primary.column_width === 'narrow';
          const columnWidth = isNarrowColumn
            ? [1, 1, 1, 1, 1 / 3]
            : [1, 1, 1, 1, 2 / 3];

          switch (
            block.__typename // eslint-disable-line no-underscore-dangle
          ) {
            case 'PrismicContentPageBodyText':
              return (
                <Item key={key} w={columnWidth}>
                  <Heading hide>{fields.title}</Heading>

                  <RichText htmlContent={block.primary.text.html} />

                  {showLanguageLink && (
                    <LanguageLink language={currentLanguage} />
                  )}

                  {footerNavigation !== null && (
                    <FooterNavigation data={footerNavigation} />
                  )}
                </Item>
              );

            case 'PrismicContentPageBodyImage':
              return (
                <Item key={key} w={columnWidth}>
                  <Gallery
                    slides={block.items}
                    isNarrow={isNarrowColumn}
                    isActive={isActive}
                  />
                </Item>
              );

            case 'PrismicContentPageBodyProjectsList':
              return (
                <Item key={key} w={1}>
                  <Heading>{fields.title}</Heading>

                  <ProjectList />
                </Item>
              );

            default:
              return null;
          }
        })}
      </>
    );
  }
}
