/*
read: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
use: https://jakearchibald.github.io/svgomg/
*/

import React from 'react';
import styled from 'styled-components';

const icons = {
  externalLink:
    '<path d="M11.058 1.625L.684 12 0 11.316 10.375.941H2V0h10v10h-.942V1.625z"/>',
};

const Icon = styled.svg`
  display: inline-block;
  fill: currentColor;
`;

export default ({ type, size }) => (
  <Icon
    width={size || 12}
    height={size || 12}
    viewBox="0 0 12 12"
    dangerouslySetInnerHTML={{ __html: icons[type] }}
  /> // eslint-disable-line react/no-danger
);
