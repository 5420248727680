import React from 'react';
import styled from 'styled-components';
import { Spring, animated, config } from 'react-spring';

import media from 'utils/media';
import grid, { GridContainer, gridItem } from 'utils/grid';

const Main = styled(GridContainer)`
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled.div`
  ${gridItem(1)};

  ${media.md`
    ${gridItem(3 / 4)}

    margin-left: ${grid(1 / 4)};
  `};
`;

const MainAnimated = animated(Main);

export default class ScrollingMain extends React.Component {
  springRef = React.createRef();

  stop = () => {
    if (!this.props.isAnimatingScroll) return;

    this.springRef.current.stop();
    this.props.scrollStop();
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldAnimateScroll;
  }

  render() {
    const {
      children,
      scrollingComponentRef,
      scrollTarget,
      ...rest
    } = this.props;

    const scrollCurrent = scrollingComponentRef.current
      ? scrollingComponentRef.current.scrollTop
      : 0;

    return (
      <Spring
        native
        reset
        ref={this.springRef}
        from={{ y: scrollCurrent }}
        to={{ y: scrollTarget }}
        config={config.slow}
      >
        {props => (
          <MainAnimated
            as="main"
            ref={scrollingComponentRef}
            scrollTop={props.y}
            onWheel={this.stop}
            onTouchStart={this.stop}
            {...rest}
          >
            <Content>{children}</Content>
          </MainAnimated>
        )}
      </Spring>
    );
  }
}
