import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';

import scale from 'utils/scale';
import media from 'utils/media';

const Wrapper = styled.figure`
  ${props =>
    props.ratio < 1.25 &&
    `
    padding: 0 ${(1.25 / 12) * 100}%;
  `}
  ${props =>
    props.ratio < 1 &&
    !props.isNarrow &&
    `
    padding: 0 ${(2 / 12) * 100}%;
  `}
  ${props =>
    props.ratio < 1 &&
    props.isNarrow &&
    `
    padding: 0 ${(2.5 / 12) * 100}%;
  `}

  ${media.sm`
    padding: 0 ${(4 / 12) * 100}% 0 0;

    ${props =>
      props.ratio < 1.25 &&
      `
      padding: 0 ${(5 / 12) * 100}% 0 0;
    `}
    ${props =>
      props.ratio < 1 &&
      !props.isNarrow &&
      `
      padding: 0 ${(6.5 / 12) * 100}% 0 0;
    `}
    ${props =>
      props.ratio < 1 &&
      props.isNarrow &&
      `
      padding: 0 ${(9 / 12) * 100}% 0 0;
    `}
  `};

  ${media.md`
    ${props =>
      props.ratio < 0.7 &&
      !props.isNarrow &&
      `
      padding: 0 ${(7 / 12) * 100}% 0 0;
    `}
  `};

  ${media.xl`
    padding: 0 ${(1 / 12) * 100}%;

    ${props =>
      props.ratio < 1.25 &&
      `
      padding: 0 ${(1.5 / 12) * 100}%;
    `}
    ${props =>
      props.ratio < 1 &&
      !props.isNarrow &&
      `
      padding: 0 ${(2.25 / 12) * 100}%;
    `}
    ${props =>
      props.ratio < 1 &&
      props.isNarrow &&
      `
      padding: 0 ${(1.75 / 12) * 100}%;
    `}
    ${props =>
      props.ratio < 0.7 &&
      !props.isNarrow &&
      `
      padding: 0 ${(2.75 / 12) * 100}%;
    `}
  `};
`;

const Caption = styled.figcaption`
  display: flex;
  padding-top: ${scale(0.25)};
  font-size: ${props => props.theme.type.smallXS.fontsize}rem;
  line-height: ${props => props.theme.type.smallXS.lineheight}rem;
  hyphens: auto;

  ${media.sm`
    font-size: ${props => props.theme.type.small.fontsize}rem;
    line-height: ${props => props.theme.type.small.lineheight}rem;
  `};
`;

const Interaction = styled(Hammer)`
  cursor: pointer;
`;

export default class Image extends React.PureComponent {
  render() {
    const {
      image,
      alt,
      caption,
      handleClick,
      handleSwipeLeft,
      handleSwipeRight,
      ...rest
    } = this.props;

    return (
      <Wrapper ratio={image.aspectRatio} {...rest}>
        {typeof handleClick !== 'undefined' ||
        typeof handleSwipeLeft !== 'undefined' ||
        typeof handleSwipeRight !== 'undefined' ? (
          <Interaction
            onSwipeLeft={handleSwipeLeft}
            onSwipeRight={handleSwipeRight}
            onClick={handleClick}
          >
            <div>
              <Img fluid={image} alt={alt} />
            </div>
          </Interaction>
        ) : (
          <Img fluid={image} alt={alt} />
        )}

        {caption && <Caption>{caption}</Caption>}
      </Wrapper>
    );
  }
}
