import React from 'react';
import styled from 'styled-components';
import { addEventListener } from 'consolidated-events';

import { GridInner } from 'utils/grid';
import { clamp } from 'utils/math';
import media from 'utils/media';
import scale from 'utils/scale';
import theme from 'utils/theme';

const isBrowser = typeof window !== 'undefined';

const topPositionComputed = theme.measures.mainOffsetTop.md * theme.scale * 16;
const pauseDistance = 120;
const fadeInDistance = 180;
const fadeOutDistance = 480; // only for content higher than viewport

const normalizeOpacity = val => clamp(val, 0, 1).toFixed(2);

const Container = styled.section`
  padding-top: ${props => scale(props.theme.measures.mainOffsetTop.xs)};
  opacity: 0;

  ${media.md`
    padding-top: 0;
  `};
`;

const Content = styled(GridInner)`
  ${media.md`
    position: sticky;
    top: ${props => scale(props.theme.measures.mainOffsetTop.md)};
  `};
`;

export default class Section extends React.PureComponent {
  parallaxRef = React.createRef();

  contentRef = React.createRef();

  viewportHeight;

  calculateSectionOpactiy = (distanceFromTop, containerHeight) => {
    let fadeInOpacity = 0;
    let fadeOutOpacity = 1;

    if (distanceFromTop <= topPositionComputed + fadeInDistance) {
      fadeInOpacity = normalizeOpacity(
        1 - (distanceFromTop - topPositionComputed) / fadeInDistance
      );
    }

    if (containerHeight - pauseDistance >= this.viewportHeight) {
      // if section-content is higher than viewport
      if (
        distanceFromTop + containerHeight <=
        topPositionComputed + fadeOutDistance
      ) {
        fadeOutOpacity = normalizeOpacity(
          1 +
            (distanceFromTop +
              containerHeight -
              topPositionComputed -
              fadeOutDistance) /
              fadeInDistance
        );
      }

      // if section-content is less high than viewport
    } else if (-(distanceFromTop - topPositionComputed) >= pauseDistance) {
      fadeOutOpacity = normalizeOpacity(
        1 +
          (distanceFromTop - topPositionComputed + pauseDistance) /
            (containerHeight - pauseDistance - fadeInDistance)
      );
    }

    return Math.min(fadeInOpacity, fadeOutOpacity);
  };

  setHeight = () => {
    if (!isBrowser) return;

    if (typeof this.viewportHeight === 'undefined') {
      this.viewportHeight = window.innerHeight;
    }

    const { isLast } = this.props;
    const rect = this.contentRef.current.getBoundingClientRect();

    const minHeight = isLast
      ? this.viewportHeight
      : rect.height + pauseDistance;

    this.parallaxRef.current.style.minHeight = `${Math.round(minHeight)}px`;
  };

  parallax = () => {
    if (!isBrowser) return;

    if (typeof this.viewportHeight === 'undefined') {
      this.viewportHeight = window.innerHeight;
    }

    const rect = this.parallaxRef.current.getBoundingClientRect();

    // abort if element is not inside viewport
    if (rect.top > this.viewportHeight || rect.top + rect.height < 0) return;

    const opacity = this.calculateSectionOpactiy(rect.top, rect.height);

    this.parallaxRef.current.style.opacity = opacity;

    // remove pointer events if opacity is low
    if (opacity < 0.05) {
      this.parallaxRef.current.style.pointerEvents = 'none';
    } else {
      this.parallaxRef.current.style.pointerEvents = 'all';
    }
  };

  createListeners = () => {
    if (!isBrowser) return;

    const { scrollingComponentRef } = this.props;

    if (scrollingComponentRef.current === null) {
      // if the scrollingComponent isn't mounted yet, retry after timeout
      setTimeout(this.createListeners, 150);
    } else {
      this.removeScrollListener = addEventListener(
        scrollingComponentRef.current,
        'scroll',
        this.scrollRaf,
        { passive: true }
      );

      this.removeResizeListener = addEventListener(
        window,
        'resize',
        this.resizeRaf,
        { passive: true }
      );
    }
  };

  removeListeners = () => {
    this.removeScrollListener();
    this.removeResizeListener();
  };

  scrollRaf = () => {
    if (!isBrowser) return;

    window.requestAnimationFrame(this.parallax);
  };

  resizeRaf = () => {
    if (!isBrowser) return;

    window.requestAnimationFrame(this.setHeight);
    window.requestAnimationFrame(this.parallax);
    // Some browsers don't fire on maximize
    setTimeout(this.setHeight, 150);
    setTimeout(this.parallax, 150);
  };

  componentDidMount() {
    this.setHeight();
    this.parallax();
    this.createListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  render() {
    const { children, innerRef } = this.props;

    return (
      <div ref={innerRef}>
        <Container ref={this.parallaxRef}>
          <Content ref={this.contentRef}>{children}</Content>
        </Container>
      </div>
    );
  }
}
