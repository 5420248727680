import React from 'react';

export default class Toggler extends React.PureComponent {
  handleToggle = () => {
    this.setState(prevState => ({
      isOn: !prevState.isOn,
    }));
  };

  state = {
    isOn: false,
    onToggle: this.handleToggle,
  };

  render() {
    return this.props.children(this.state);
  }
}
