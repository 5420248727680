import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import normalizeProjectData from 'utils/normalizeProjectData';

import Projects from './Projects';

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectsQuery {
        allPrismicProject(
          sort: { fields: [data___year_from, data___year_to], order: DESC }
        ) {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
                year_from
                year_to
                location
                project_url {
                  url
                  target
                }
                types {
                  type
                }
                roles {
                  role
                }
                collaboration
              }
            }
          }
        }
      }
    `}
    render={projectData => (
      <Projects data={normalizeProjectData(projectData)} />
    )}
  />
);
