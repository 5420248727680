import React from 'react';
import Helmet from 'react-helmet';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { ScrollerProvider } from 'modules/Scroller';
import Page from 'pages/index';
import SimplePage from 'templates/SimplePage';

import theme from 'utils/theme';
import media from 'utils/media';

import untitledSansRegularWoff2 from 'assets/fonts/UntitledSansWeb-Regular.woff2';
import untitledSansRegularWoff1 from 'assets/fonts/UntitledSansWeb-Regular.woff';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    background: ${theme.color.bg};

    ${media.xsOnly`
      font-size: ${theme.type.baseMobile}%;
    `}
  }

  body {
    font-family: ${theme.font.base};
    font-size: ${theme.type.base.fontsize}rem;
    line-height: ${theme.type.base.lineheight}rem;
    color: ${theme.color.base};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${theme.color.base};
    text-decoration: none;
  }
`;

export default class Layout extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <>
        <Helmet>
          <style type="text/css">
            {`
              @font-face {
                font-family: 'UntitledSans';
                font-style: normal;
                font-weight: normal;
                src: url(${untitledSansRegularWoff2}) format('woff2'),
                  url(${untitledSansRegularWoff1}) format('woff');
              }
            `}
          </style>
        </Helmet>

        <GlobalStyle />

        <ThemeProvider theme={theme}>
          <ScrollerProvider>
            {children.props.pageContext.simple ? (
              <SimplePage {...children.props} />
            ) : (
              <Page {...children.props} />
            )}
          </ScrollerProvider>
        </ThemeProvider>
      </>
    );
  }
}
