import styled from 'styled-components';

import media from 'utils/media';
import scale from 'utils/scale';

export default styled.li`
  display: block;
  height: ${props => props.theme.type.base.lineheight}rem;
  transition: all 0.25s ease-out;

  ${props =>
    props.isExpanded &&
    props.spaceAfter &&
    `
    margin-bottom: ${scale(0.75)};
  `};

  ${media.md`
    ${props =>
      props.spaceAfter &&
      `
      margin-bottom: ${scale(1)};
    `}

    ${props =>
      props.isLogo &&
      `
      margin-bottom: ${scale(2)};
    `}
  `};

  a {
    display: inline-block;
    color: ${props =>
      props.isActive
        ? props.theme.color.base
        : props.isExpanded
          ? props.theme.color.gray
          : props.theme.color.bg};

    transition: ${props =>
      props.isActive ? 'color 0.5s ease-out' : 'color 0.15s ease-out'};

    pointer-events: ${props => (props.isExpanded ? 'auto' : 'none')};

    ${media.md`
      color: ${props =>
        props.isActive ? props.theme.color.base : props.theme.color.gray};
      pointer-events: auto;
    `};

    &:hover,
    &:focus {
      ${media.md`
        color: ${props =>
          props.isActive
            ? props.theme.color.base
            : props.theme.color.lightGray};
      `};
    }
  }
`;
