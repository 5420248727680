export default data =>
  data.allPrismicProject.edges.map(({ node }) => ({
    id: node.uid,
    title: node.data.title.text,
    location: node.data.location,
    projectUrl: node.data.project_url,
    yearFrom: node.data.year_from,
    yearTo: node.data.year_to,
    types: node.data.types.map(item => item.type.replace(' and ', ' & ')),
    roles: node.data.roles.map(item =>
      item.role
        .replace(' and ', ' & ')
        .replace('DCA', 'David Chipperfield Architects')
    ),
    collaboration:
      node.data.collaboration === 'none' ? null : node.data.collaboration,
  }));
