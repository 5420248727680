import React from 'react';
import styled from 'styled-components';

import media from 'utils/media';
import scale from 'utils/scale';

const RichText = styled.div`
  p,
  ol,
  ul {
    padding: 0 0 ${props => props.theme.type.base.lineheight / 2}rem;
    margin: 0;
    hyphens: auto;
    max-width: 480px;

    ${media.md`
      max-width: 560px;
      padding-bottom: ${props => props.theme.type.base.lineheight}rem;
    `};

    ${media.lg`
      max-width: 640px;
    `};

    a {
      color: ${props => props.theme.color.base};
      border-bottom: 1px solid ${props => props.theme.color.base};
      transition: all 0.05s ease-in-out;

      &:hover,
      &:focus {
        color: ${props => props.theme.color.darkGray};
        border-color: ${props => props.theme.color.darkGray};
      }

      &:active {
        color: ${props => props.theme.color.mediumGray};
        border-color: ${props => props.theme.color.mediumGray};
      }
    }
  }

  ol,
  ul {
    padding-left: ${scale(1.25)};
    list-style: none;
    counter-reset: list-counter;

    li {
      position: relative;
      counter-increment: list-counter;

      &::before {
        position: absolute;
        top: 0;
        left: -${scale(0.875)};
        font-variant-numeric: tabular-nums;
      }
    }
  }

  ul {
    li {
      &::before {
        content: '–';
      }
    }
  }

  ol {
    li {
      &::before {
        content: counter(list-counter) '.';
      }
    }
  }

  h3 {
    margin: ${scale(1.5)} 0 ${scale(1)};
    font-size: ${props => props.theme.type.large.fontsize}rem;
    line-height: ${props => props.theme.type.large.lineheight}rem;
    font-weight: normal;

    ${media.md`
      margin-bottom: ${scale(0.75)};
    `};

    ${media.xl`
      margin-top: ${scale(2)};
      margin-bottom: ${scale(0.5)};
      min-height: ${props => props.theme.type.large.lineheight * 2}rem;
    `};
  }
`;

export default ({ htmlContent, children, ...props }) => {
  if (typeof htmlContent !== 'undefined') {
    return (
      <RichText dangerouslySetInnerHTML={{ __html: htmlContent }} {...props} />
    ); // eslint-disable-line react/no-danger
  }
  return <RichText {...props}>{children}</RichText>;
};
