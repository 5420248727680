import React from 'react';
import styled from 'styled-components';

import scale from 'utils/scale';
import { gridItem, gridContainer } from 'utils/grid';
import media from 'utils/media';

const Wrapper = styled.header`
  ${gridContainer};
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;

  ${media.md`
    width: calc((100% - ${props =>
      scale(props.theme.grid.outerMargin.md[1] * 2)}) / 4 + ${props =>
    scale(props.theme.grid.outerMargin.md[1])});
    padding-right: 0;
  `};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  transform: translateY(-${props => scale(props.activeIndex)});
  transition: transform 0.25s ease-out;

  ${media.md`
    transform: none;
    transition: none;
  `};

  ${props =>
    props.isExpanded &&
    `
      li {
        height: ${scale(1.5)};

        a {
          color: ${props.theme.color.gray};
        }
      }
  `};
`;

const Container = styled.nav`
  ${gridItem(1)};

  position: relative;
  padding-top: ${props => scale(props.theme.grid.outerMargin.xs[0])};
  padding-bottom: ${props => scale(props.theme.grid.outerMargin.xs[0])};
  height: ${props => (props.isExpanded ? '100vh' : scale(3))};
  background: ${props => props.theme.color.bg};
  overflow: hidden;
  transition: height 0.25s ease-out;
  -webkit-overflow-scrolling: touch;

  ${media.md`
    padding-top: ${props => scale(props.theme.grid.outerMargin.md[0])};
    padding-bottom: ${props => scale(props.theme.grid.outerMargin.md[0])};
    height: 100vh;
    overflow-y: auto;
  `};

  ${props =>
    props.isExpanded &&
    `
    overflow-y: auto;
  `};

  ${List} {
    ${props =>
      props.isExpanded &&
      `
      transform: translateY(0);
    `};
  }
`;

const Line = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 1px;
  background: ${props => props.theme.color.base};
  transition: all 0.15s ease-out;
`;

const Toggle = styled.button`
  position: absolute;
  right: ${scale(0.25)};
  top: ${scale(0.45)};
  height: 49px;
  width: 48px;
  border: none;
  background: none;
  outline: none;

  ${Line}:nth-child(1) {
    top: 18px;
  }
  ${Line}:nth-child(2) {
    top: 24px;
  }
  ${Line}:nth-child(3) {
    bottom: 18px;
  }

  ${Line}:nth-child(1) {
    ${props =>
      props.isExpanded &&
      `
      transform: translateY(5px);
      opacity: 0;
      `};
  }

  ${Line}:nth-child(3) {
    ${props =>
      props.isExpanded &&
      `
      transform: translateY(-5px);
      opacity: 0;
      `};
  }

  ${media.md`
    display: none;
  `};
`;

export default ({ children, isExpanded, onToggle, activeIndex, ...props }) => (
  <Wrapper>
    <Container isExpanded={isExpanded} {...props}>
      <List activeIndex={activeIndex}>{children}</List>

      <Toggle isExpanded={isExpanded} onClick={onToggle}>
        <Line />
        <Line />
        <Line />
      </Toggle>
    </Container>
  </Wrapper>
);
