import React from 'react';
import Context from './Context';

export const ScrollerConsumer = ({ children }) => (
  <Context.Consumer>{children}</Context.Consumer>
);

export class ScrollerProvider extends React.PureComponent {
  scrollingComponentRef = React.createRef();

  scrollTo = ({ to }) => {
    /* eslint-disable react/no-unused-state */
    this.setState({
      scrollTarget: to,
      shouldAnimateScroll: true,
      isAnimatingScroll: true,
    });
    /* eslint-enable react/no-unused-state */
  };

  scrollStop = () => {
    this.setState(state => {
      if (!state.isAnimatingScroll && !state.shouldAnimateScroll) {
        return null;
      }
      return {
        shouldAnimateScroll: false,
        isAnimatingScroll: false,
      };
    });
  };

  /* eslint-disable react/no-unused-state */
  state = {
    scrollingComponentRef: this.scrollingComponentRef,
    isAnimatingScroll: false,
    shouldAnimateScroll: false,
    scrollTo: this.scrollTo,
    scrollStop: this.scrollStop,
    scrollTarget: 0,
  };
  /* eslint-enable react/no-unused-state */

  render() {
    const { children } = this.props;

    return <Context.Provider value={this.state}>{children}</Context.Provider>;
  }
}
