export default {
  breakpoints: ['40em', '60em', '68em', '75em'],
  scale: 1.75, // (in rem) => scale(1) = lineheight (28px)

  grid: {
    gutter: 1.5, // = 42px
    outerMargin: {
      xs: [1, 0.25], // sides take half gutter into account
      md: [1.25, 0.75],
    },
  },

  measures: {
    mainOffsetTop: {
      xs: 3 + 1, // in scale
      md: 1.25 + 3, // in scale
    },
  },

  font: {
    base: 'UntitledSans, sans-serif',
  },

  type: {
    baseMobile: 85.7142, // % (makes lineheight exactly 24px)
    base: {
      fontsize: 18 / 16, // (rem)
      lineheight: 28 / 16,
    },
    large: {
      fontsize: 24 / 16,
      lineheight: 35 / 16,
    },
    small: {
      fontsize: 14 / 16,
      lineheight: 21 / 16,
    },
    smallXS: {
      fontsize: 15 / 16,
      lineheight: 24 / 16,
    },
  },

  color: {
    bg: 'rgba(255, 255, 255, 1)',
    base: 'rgba(0, 0, 0, 1)',
    gray: 'rgba(0, 0, 0, 0.18)',
    lightGray: 'rgba(0, 0, 0, 0.28)',
    mediumGray: 'rgba(0, 0, 0, 0.4)',
    darkGray: 'rgba(0, 0, 0, 0.65)',
    overlayGray: 'rgba(255, 255, 255, 0.82)', // should be "reverse" of gray
  },
};
