import styled, { css } from 'styled-components';

import media from 'utils/media';
import scale from 'utils/scale';

export default value => `${value * 100}%`;

export const gridItem = width => css`
  padding-left: ${props => scale(props.theme.grid.gutter / 2)};
  padding-right: ${props => scale(props.theme.grid.gutter / 2)};

  ${typeof width === 'undefined' &&
    `
    width: auto;
  `};

  ${!Array.isArray(width) &&
    typeof width === 'number' &&
    `
    width: ${width * 100}%;
  `};

  ${Array.isArray(width) &&
    typeof width[0] === 'number' &&
    `
    width: ${width[0] * 100}%;
  `};

  ${Array.isArray(width) &&
    width[0] === 'auto' &&
    `
    width: auto;
  `};

  ${Array.isArray(width) &&
    typeof width[1] === 'number' &&
    media.sm`
    width: ${width[1] * 100}%;
  `};

  ${Array.isArray(width) &&
    width[1] === 'auto' &&
    media.sm`
    width: auto;
  `};

  ${Array.isArray(width) &&
    typeof width[2] === 'number' &&
    media.md`
    width: ${width[2] * 100}%;
  `};

  ${Array.isArray(width) &&
    width[2] === 'auto' &&
    media.md`
    width: auto;
  `};

  ${Array.isArray(width) &&
    typeof width[3] === 'number' &&
    media.lg`
    width: ${width[3] * 100}%;
  `};

  ${Array.isArray(width) &&
    width[3] === 'auto' &&
    media.lg`
    width: auto;
  `};

  ${Array.isArray(width) &&
    typeof width[4] === 'number' &&
    media.xl`
    width: ${width[4] * 100}%;
  `};

  ${Array.isArray(width) &&
    width[4] === 'auto' &&
    media.xl`
    width: auto;
  `};
`;

export const gridContainer = css`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding-left: ${props => scale(props.theme.grid.outerMargin.xs[1])};
  padding-right: ${props => scale(props.theme.grid.outerMargin.xs[1])};

  ${media.md`
    padding-left: ${props => scale(props.theme.grid.outerMargin.md[1])};
    padding-right: ${props => scale(props.theme.grid.outerMargin.md[1])};
  `};
`;

export const gridInner = css`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -${props => scale(props.theme.grid.gutter / 2)};
  margin-right: -${props => scale(props.theme.grid.gutter / 2)};
`;

export const GridItem = styled.div`
  ${props => gridItem(props.w)};
`;

export const GridContainer = styled.div`
  ${gridContainer};
`;

export const GridInner = styled.div`
  ${gridInner};
`;
