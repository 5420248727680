export const padNumber = (num, pad) => {
  const sign = Math.sign(num) === -1 ? '-' : '';

  return (
    sign +
    new Array(pad)
      .concat([Math.abs(num)])
      .join('0')
      .slice(-pad)
  );
};

export const format = 0;
