import React from 'react';
import styled from 'styled-components';

import { padNumber } from 'utils/format';
import media from 'utils/media';
import scale from 'utils/scale';

import Footnote from 'atoms/Footnote';
import Project from './Project';

const List = styled.ol`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Notes = styled.div`
  padding-top: ${scale(1.5)};
  font-size: ${props => props.theme.type.smallXS.fontsize}rem;
  line-height: ${props => props.theme.type.smallXS.lineheight}rem;

  ${media.sm`
    font-size: ${props => props.theme.type.small.fontsize}rem;
    line-height: ${props => props.theme.type.small.lineheight}rem;
  `};
`;

export default ({ data }) => (
  <>
    <List>
      {data &&
        data.map((project, i) => (
          <Project
            num={padNumber(data.length - i, 2)}
            key={project.id}
            data={project}
          />
        ))}
    </List>

    <Notes>
      <p>
        <Footnote>1</Footnote> In personal collaboration with David Chipperfield
      </p>
    </Notes>
  </>
);
