import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Main from 'components/Main';
import Section from 'components/Section';
import Navigation from 'components/Navigation';
import NavigationItem from 'components/NavigationItem';
import { ScrollerConsumer } from 'modules/Scroller';
import Scrollspy from 'modules/Scrollspy';
import Toggler from 'modules/Toggler';
import ContentPage from 'templates/ContentPage';

import { getI18nPrefix, getI18nPrefixedSlug } from 'utils/i18n';

export default class Page extends React.PureComponent {
  render() {
    const { data } = this.props;

    const { lang, defaultLang, langLinks } = data.content.edges[0].node.fields;

    const baseData = data.content.edges[0].node.data;

    const footerNavItems = data.content.edges[0].node.data.footer_navigation_items.map(
      item => ({
        title: item.title,
        key: item.link.document[0].uid,
        target: getI18nPrefixedSlug(
          item.link.document[0].uid,
          lang,
          defaultLang
        ),
      })
    );

    const navItems = data.content.edges[0].node.data.navigation_items.map(
      (item, index) => ({
        title: item.title,
        key: item.link.document[0].uid,
        target: item.link.document[0].uid,
        isLogo: index === 0,
        spaceAfter: item.bool_spacing === 'yes',
      })
    );

    const sections = data.content.edges[0].node.data.navigation_items.map(
      item => ({
        key: item.link.document[0].uid,
        section: item.link.document[0].uid,
        pageData: item.link.document[0],
      })
    );

    const allSections = sections.map(item => item.section);

    return (
      <>
        <Helmet>
          <html lang={lang} />

          <title>{baseData.page_title}</title>
          <meta name="description" content={baseData.page_description} />

          {langLinks.map(link => (
            <link
              key={link.lang}
              rel="alternate"
              hrefLang={link.lang}
              href={link.url}
            />
          ))}

          {baseData.opengraph_image.url && (
            <meta property="og:image" content={baseData.opengraph_image.url} />
          )}
        </Helmet>

        <ScrollerConsumer>
          {scrollerContext => (
            <Scrollspy
              routerPath={`${getI18nPrefix(lang, defaultLang)}/:section`}
              scrollerContext={scrollerContext}
              sections={allSections}
            >
              {({ activeSection, linkProps, sectionProps }) => (
                <>
                  <Toggler>
                    {({ isOn, onToggle }) => (
                      <Navigation
                        activeIndex={allSections.indexOf(activeSection)}
                        onToggle={onToggle}
                        isExpanded={isOn}
                      >
                        {navItems.map(({ title, ...itemProps }) => (
                          <NavigationItem
                            isExpanded={isOn}
                            isActive={itemProps.target === activeSection}
                            onClick={onToggle}
                            {...itemProps}
                            {...linkProps}
                          >
                            {title}
                          </NavigationItem>
                        ))}
                      </Navigation>
                    )}
                  </Toggler>

                  <Main
                    shouldAnimateScroll={scrollerContext.shouldAnimateScroll}
                    isAnimatingScroll={scrollerContext.isAnimatingScroll}
                    scrollStop={scrollerContext.scrollStop}
                    scrollTarget={scrollerContext.scrollTarget}
                    scrollingComponentRef={
                      scrollerContext.scrollingComponentRef
                    }
                  >
                    {sections.map(({ pageData, ...itemProps }, i) => (
                      <Scrollspy.Section {...itemProps} {...sectionProps}>
                        <Section
                          scrollingComponentRef={
                            scrollerContext.scrollingComponentRef
                          }
                          isLast={i === sections.length - 1}
                        >
                          <ContentPage
                            uid={itemProps.key}
                            data={pageData}
                            isActive={itemProps.key === activeSection}
                            showLanguageLink={i === 0}
                            currentLanguage={lang}
                            footerNavigation={
                              i === sections.length - 1 ? footerNavItems : null
                            }
                          />
                        </Section>
                      </Scrollspy.Section>
                    ))}
                  </Main>
                </>
              )}
            </Scrollspy>
          )}
        </ScrollerConsumer>
      </>
    );
  }
}

export const query = graphql`
  query MainQuery($lang: String!) {
    content: allPrismicSettings(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          fields {
            lang
            defaultLang
            langLinks {
              lang
              url
            }
          }
          data {
            page_title
            page_description
            opengraph_image {
              url
            }
            footer_navigation_items {
              title
              link {
                document {
                  uid
                }
              }
            }
            navigation_items {
              title
              bool_spacing
              link {
                document {
                  uid
                  fields {
                    title
                  }
                  data {
                    body {
                      __typename
                      ... on PrismicContentPageBodyText {
                        id
                        primary {
                          column_width
                          text {
                            html
                          }
                        }
                      }
                      ... on PrismicContentPageBodyImage {
                        id
                        primary {
                          column_width
                        }
                        items {
                          image {
                            localFile {
                              childImageSharp {
                                fluid {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                            }
                            alt
                          }
                          caption
                        }
                      }
                      ... on PrismicContentPageBodyProjectsList {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
