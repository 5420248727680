import styled from 'styled-components';

import { visuallyHidden } from 'utils/a11y';
import scale from 'utils/scale';

export default styled.h1`
  margin: 0 0 ${scale(1.375)};
  font-size: ${props => props.theme.type.base.fontsize}rem;
  line-height: ${props => props.theme.type.base.lineheight}rem;
  font-weight: normal;

  ${props => props.hide && visuallyHidden};
`;
