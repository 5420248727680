import { css } from 'styled-components';

import theme from 'utils/theme';

const sizes = theme.breakpoints;
const sizeNames = ['sm', 'md', 'lg', 'xl'];

// Iterate through the sizes and create a media template
const media = sizes.reduce((acc, size, i) => {
  acc[sizeNames[i]] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

media.xsOnly = (...args) => css`
  @media (max-width: ${sizes[0]}) {
    ${css(...args)};
  }
`;

export default media;
