import React from 'react';
import styled from 'styled-components';

import media from 'utils/media';
import scale from 'utils/scale';
import { GridInner, GridItem } from 'utils/grid';

import Footnote from 'atoms/Footnote';
import Icon from 'atoms/Icon';

const Item = styled.li`
  position: relative;
  display: block;
  margin: 0;
  border: 1px solid ${props => props.theme.color.base};
  border-width: 1px 0 0;
  padding: calc(${scale(0.625)} - 1px) 0 ${scale(0.625)};
  padding-left: ${scale(1.25)};
  color: ${props => props.theme.color.base};

  :last-child {
    border-width: 1px 0;
    padding-bottom: calc(${scale(0.625)} - 1px);
  }

  ${media.sm`
    padding-left: ${scale(1.75)};
  `};
`;

const Title = styled(GridItem)`
  padding-bottom: ${scale(0.25)};

  ${media.sm`
    padding-bottom: ${scale(0.5)};
    padding-right: ${scale(1.75)};
  `};

  a {
    border-bottom: 1px solid transparent;
    transition: all 0.05s ease-in-out;
    pointer-events: all;

    &:hover,
    &:focus {
      border-color: ${props => props.theme.color.base};
    }

    &:active {
      color: ${props => props.theme.color.darkGray};
      border-color: ${props => props.theme.color.darkGray};
    }
  }
`;

const LinkIndicator = styled.div`
  position: absolute;
  z-index: 5;
  right: ${props => scale(props.theme.grid.gutter / 2)};
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.theme.type.smallXS.lineheight}rem;
  pointer-events: all;

  ${media.sm`
    top: ${scale(0.0625)};
  `};
`;

const Link = styled.a`
  display: inline;
  width: 16px;
  height: ${props => props.theme.type.base.lineheight}rem;
  border-bottom: 1px solid transparent;
  text-align: center;
  transition: all 0.05s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${props => props.theme.color.base};
  }

  &:active {
    color: ${props => props.theme.color.darkGray};
    border-color: ${props => props.theme.color.darkGray};
  }
`;

const InfoLabel = styled(GridItem)`
  ${media.xsOnly`
    display: inline-block;
    padding-left: 0;
    padding-right: ${scale(0.25)};
  `};
`;

const InfoContent = styled(GridItem)`
  ${media.xsOnly`
    display: contents;
  `};
`;

const InfoContainer = styled(GridInner)`
  ${media.xsOnly`
    display: contents;
  `};
`;

const Info = styled(GridItem)`
  font-size: ${props => props.theme.type.smallXS.fontsize}rem;
  line-height: ${props => props.theme.type.smallXS.lineheight}rem;

  ${media.sm`
    font-size: ${props => props.theme.type.small.fontsize}rem;
    line-height: ${props => props.theme.type.small.lineheight}rem;
  `};
`;

const TabularNumber = styled.span`
  font-variant-numeric: tabular-nums;
`;

const NumAndYear = styled(GridItem)`
  padding-bottom: ${scale(0.25)};
  font-size: ${props => props.theme.type.smallXS.fontsize}rem;
  line-height: ${props => props.theme.type.smallXS.lineheight}rem;

  ${media.sm`
    padding-top: ${scale(0.125)};
    padding-bottom: 0;
    font-size: ${props => props.theme.type.small.fontsize}rem;
    line-height: ${props => props.theme.type.small.lineheight}rem;
  `};

  ${TabularNumber} {
    display: inline-block;

    &:first-child {
      width: ${scale(1.25)};
      margin-left: -${scale(1.25)};

      ${media.sm`
        width: ${scale(1.75)};
        margin-left: -${scale(1.75)};
      `};

      ${media.md`
        padding-right: ${props => scale(props.theme.grid.gutter)};
      `};
    }
  }
`;

export default ({ data, num, ...rest }) => (
  <Item {...rest}>
    <GridInner>
      <NumAndYear w={[1, 3 / 12, 3 / 12, 3 / 12, 3 / 12]}>
        <TabularNumber>{num}</TabularNumber>
        <TabularNumber>
          {data.yearFrom}–{data.yearTo}
        </TabularNumber>
      </NumAndYear>

      <Title w={[1, 9 / 12, 9 / 12, 9 / 12, 9 / 12]}>
        {data.projectUrl !== null ? (
          <a href={data.projectUrl.url} target={data.projectUrl.target}>
            {data.title}
          </a>
        ) : (
          data.title
        )}
        {data.collaboration !== null && <Footnote>1</Footnote>}
      </Title>

      {data.projectUrl !== null && (
        <LinkIndicator>
          <Link href={data.projectUrl.url} target={data.projectUrl.target}>
            <Icon type="externalLink" />
          </Link>
        </LinkIndicator>
      )}

      <Info w={[1, 1, 1, 1, 1]}>
        <InfoContainer>
          <InfoLabel w={['auto', 3 / 12, 3 / 12, 3 / 12, 3 / 12]}>
            Location:
          </InfoLabel>
          <InfoContent w={['auto', 9 / 12, 9 / 12, 9 / 12, 9 / 12]}>
            {data.location}
          </InfoContent>
        </InfoContainer>
      </Info>

      <Info w={[1]}>
        <InfoContainer>
          <InfoLabel w={['auto', 3 / 12, 3 / 12, 3 / 12, 3 / 12]}>
            Type:
          </InfoLabel>
          <InfoContent w={['auto', 9 / 12, 9 / 12, 9 / 12, 9 / 12]}>
            {data.types.join(', ')}
          </InfoContent>
        </InfoContainer>
      </Info>

      <Info w={[1]}>
        <InfoContainer>
          <InfoLabel w={['auto', 3 / 12, 3 / 12, 3 / 12, 3 / 12]}>
            Role:
          </InfoLabel>
          <InfoContent w={['auto', 9 / 12, 9 / 12, 9 / 12, 9 / 12]}>
            {data.roles.join(', ')}
          </InfoContent>
        </InfoContainer>
      </Info>
    </GridInner>
  </Item>
);
