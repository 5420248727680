import React from 'react';
import styled from 'styled-components';

import scale from 'utils/scale';

import Image from 'components/Image';

const Slide = styled.figure`
  z-index: ${props => (props.isActive ? 1 : -1)};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.75s ease-in-out;

  &:first-child {
    position: static;
  }
`;

const Index = styled.span`
  padding-right: ${scale(0.5)};
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
`;

export default ({
  image,
  alt,
  caption,
  index,
  numSlides,
  isNarrow,
  onNext,
  onPrev,
  ...rest
}) => {
  const slideCaption = (
    <>
      <Index>{`${index + 1}/${numSlides}`}</Index>

      {caption && <span>{caption}</span>}
    </>
  );

  return (
    <Slide {...rest}>
      <Image
        image={image}
        alt={alt}
        caption={slideCaption}
        handleClick={onNext}
        handleSwipeLeft={onNext}
        handleSwipeRight={onPrev}
        isNarrow={isNarrow}
      />
    </Slide>
  );
};
