import React from 'react';
import Waypoint from 'react-waypoint';

import theme from 'utils/theme';

import { ENTER, LEAVE } from './constants';

const topPositionComputed = theme.measures.mainOffsetTop.md * theme.scale * 16;
const buffer = 5;

export default ({ section, innerRef, handleTraverse, children, ...props }) => (
  <div id={section} ref={innerRef} {...props}>
    <Waypoint
      topOffset={`${topPositionComputed + buffer}px`}
      fireOnRapidScroll={false}
      onEnter={data => handleTraverse({ section, direction: ENTER, ...data })}
      onLeave={data => handleTraverse({ section, direction: LEAVE, ...data })}
    >
      {children}
    </Waypoint>
  </div>
);
