const preferDefault = m => (m && m.default) || m;

const React = require('react');
const Layout = preferDefault(require('./src/components/Layout.js'));

// global layout component
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

// prevent scrolling
exports.shouldUpdateScroll = () => false;
