import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

export default class Slider extends React.Component {
  handleSlideChange = nextSlide => {
    this.setState({
      activeSlide: nextSlide,
    });
  };

  onNext = () => {
    const { activeSlide } = this.state;
    const { numSlides } = this.props;

    const nextSlide = activeSlide < numSlides - 1 ? activeSlide + 1 : 0;

    this.handleSlideChange(nextSlide);
  };

  onPrev = () => {
    const { activeSlide } = this.state;
    const { numSlides } = this.props;

    const nextSlide = activeSlide > 0 ? activeSlide - 1 : numSlides - 1;

    this.handleSlideChange(nextSlide);
  };

  state = {
    activeSlide: 0,
    handleNext: this.onNext,
    handlePrev: this.onPrev,
  };

  render() {
    return <Container>{this.props.children(this.state)}</Container>;
  }
}
