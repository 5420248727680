import styled from 'styled-components';

import scale from 'utils/scale';

export default styled.sup`
  position: relative;
  top: -0.625em;
  padding-left: ${scale(0.125)};
  font-size: 0.625em;
  font-variant-numeric: tabular-nums;
  vertical-align: baseline;
`;
